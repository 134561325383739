import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
// Style
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Calendar.css';
import cs from '../../../components/commonStyle.css';
import { Button } from 'react-bootstrap';
import cx from 'classnames';

// Component
import Loader from '../../Loader';
import history from '../../../core/history';

// Locale
import messages from '../../../locale/messages';

//Images
import arrow from '/public/siteImages/whiteArrow.svg';
class BookingButton extends Component {
  static propTypes = {
    availability: PropTypes.bool.isRequired,
    isDateChosen: PropTypes.bool.isRequired,
    basePrice: PropTypes.number.isRequired,
    isHost: PropTypes.bool.isRequired,
    bookingProcess: PropTypes.any.isRequired,
    listId: PropTypes.number.isRequired,
    guests: PropTypes.number.isRequired,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    bookingType: PropTypes.string.isRequired,
    bookingLoading: PropTypes.bool,
    formatMessage: PropTypes.any,
    maximumStay: PropTypes.bool,
    userBanStatus: PropTypes.number,
    isUserVerified: PropTypes.number,
  };
  static defaultProps = {
    availability: true,
    isDateChosen: false,
    bookingLoading: false,
    isUserVerified: 0,
  };
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  handleClick() {
    const {
      bookingProcess,
      listId,
      guests,
      startDate,
      endDate,
      startTime,
      endTime,
      deliveryStatus,
      roomType,
    } = this.props;
    bookingProcess(
      listId,
      guests,
      startDate,
      endDate,
      null,
      startTime,
      endTime,
      deliveryStatus,
      roomType
    );
  }
  handleChange() {
    history.push('/s');
  }
  render() {
    const {
      basePrice,
      userBanStatus,
      isDateChosen,
      availability,
      isHost,
      bookingType,
      bookingLoading,
      isUserVerified,
    } = this.props;
    const { formatMessage } = this.props.intl;
    const { maximumStay, isDisabled } = this.props;

    let disabled, buttonLabel;
    if (isDisabled || basePrice < 1 || isHost || maximumStay || userBanStatus) {
      disabled = true;
    } else {
      disabled = false;
    }
    if (bookingType === 'instant') {
      buttonLabel = messages.bookNow;
    } else {
      buttonLabel = messages.requestToBook;
    }
    if (isUserVerified === 1) {
      if (!availability && isDateChosen) {
        return (
          <>
            <Button
              className={cx(cs.btnBig, cs.btnPrimaryBorder, cs.fullWidth)}
              onClick={this.handleChange}
            >
              <FormattedMessage {...messages.viewOtherListings} />
            </Button>
          </>
        );
      } else {
        return (
          <div className="arButtonLoader">
            <Loader
              type={'button'}
              className={cx(cs.btnBig, cs.btnPrimary, cs.fullWidth)}
              handleClick={this.handleClick}
              disabled={disabled}
              show={bookingLoading}
              label={formatMessage(buttonLabel)}
              image={arrow}
            />
          </div>
        );
      }
    } else {
      return (
        <div className="arButtonLoader">
          <Button
            className={cx(cs.btnBig, cs.btnPrimary, cs.fullWidth)}
            onClick={() => {
              history.push('/user/verification');
            }}
          >
            <FormattedMessage {...messages.pleaseVerifyId} />
          </Button>
        </div>
      );
    }
  }
}
export default injectIntl(withStyles(s, cs)(BookingButton));
