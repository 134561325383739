import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './DocumentVerification.css';
import cs from '../../components/commonStyle.css';
import cx from 'classnames';
import { Grid, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';

// Translation
import { injectIntl, FormattedMessage } from 'react-intl';

// Locale
import messages from '../../locale/messages';

import DocumentUpload from '../../components/DocumentUpload';
import DashboardSideMenu from '../../components/Dashboard/DashboardSideMenu';
import VeriffButton from './components/veriffButton';
class DocumentVerification extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    account: PropTypes.shape({
      userId: PropTypes.string,
    }),
  };

  render() {
    const {
      account: { userId },
    } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <Grid fluid className={'listingContainer'}>
        <Row>
          <Col xs={12} sm={12} md={4} lg={4} className={cs.spaceTop6}>
            <DashboardSideMenu isTrustVerfiedPage />
          </Col>
          <Col xs={12} sm={12} md={8} lg={8} className={cs.spaceTop6}>
            <div className={cx('youcarsBg', 'documentDropCss')}>
              <h3 className={s.infoTitle}>
                <FormattedMessage {...messages.documentVerification} />
              </h3>
              <VeriffButton />
              <p className={cx(cs.commonMediumText, s.veriffInfo)}>
                Ventu uses{' '}
                <a href="https://www.veriff.com/" target="_blank">
                  Veriff
                </a>{' '}
                for identity verification. This process is required for all
                hosts and guests.
              </p>
            </div>
          </Col>
        </Row>
      </Grid>
    );
  }
}

const mapState = (state) => ({
  account: state?.account?.data,
});
const mapDispatch = {};

export default injectIntl(
  withStyles(s, cs)(connect(mapState, mapDispatch)(DocumentVerification))
);
