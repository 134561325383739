require('dotenv').config();

/* eslint-disable max-len */

export const port = process.env.PORT || 3000;
export const host = process.env.WEBSITE_HOSTNAME || `localhost:${port}`;
export const url = process.env.WEBSITE_URL || 'http://localhost:3001';
export const sitename = 'Ventu Car Rental';

// default locale is the first one
export const locales = ['en-US', 'es'];

export const databaseUrl = process.env.DATABASE_URL;

// Listing Photos Upload Directory
export const listingUploadDir =
  process.env.LISTING_UPLOAD_DIR || './images/upload/';
export const getListingUploadDir =
  process.env.GET_LISTING_UPLOAD_DIR || './images/upload/';

// Home Logo upload directory
export const homeLogoUploadDir =
  process.env.LOGO_UPLOAD_DIR || './images/homeLogo/';

// Home page Banner upload directory
export const bannerUploadDir =
  process.env.BANNER_UPLOAD_DIR || './images/banner/';
export const getBannerUploadDir =
  process.env.GET_BANNER_UPLOAD_DIR || '/images/banner/';

// User Profile Photos Upload Directory
export const profilePhotoUploadDir =
  process.env.PROFILE_PHOTO_UPLOAD_DIR || './images/avatar/';

//User Document Upload
export const userDocumentUploadDir =
  process.env.USER_DOCUMENT_UPLOAD_DIR || './documents/user-documents';
export const userDocumentDir =
  process.env.USER_DOCUMENT_DIR || '/documents/user-documents';

//Listing Document Upload
export const listingDocumentUploadDir =
  process.env.LISTING_DOCUMENT_UPLOAD_DIR || './documents/listing-documents/';
export const listingDocumentDir =
  process.env.LISTING_DOCUMENT_DIR || '/documents/listing-documents/';

// Location upload directory
export const locationUploadDir =
  process.env.LOCATION_UPLOAD_DIR || './images/popularLocation/';

// Get Popular Location directory
export const getPopularLocationDir =
  process.env.GET_LOCATION_DIR || '/images/popularLocation/';

// Homepage images
export const homeBannerUploadDir =
  process.env.HOME_BANNER_UPLOAD_DIR || './images/home/';
export const getHomeBannerUploadDir =
  process.env.GET_HOME_PAGE_BANNER_DIR || '/images/home/';

// claim images
export const claimImagesUploadDir =
  process.env.CLAIM_IMAGES_UPLOAD_DIR || './images/claims/';
export const getClaimImageDir =
  process.env.GET_CLAIM_IMAGE_DIR || '/images/claims/';

//favicon dir
export const faviconUploadDir =
  process.env.FAVICON_UPLOAD_DIR || './images/favicon/';
export const getFavIconDir = process.env.GET_FAVICON_DIR || '/images/favicon/';

// whyHostUploadDir
export const whyHostUploadDir =
  process.env.WHYHOST_UPLOAD_DIR || './images/whyhost/';
export const getWhyHostDir = process.env.GET_WHYHOST_DIR || '/images/whyhost/';

// Logo upload directory
export const logoUploadDir = process.env.LOGO_UPLOAD_DIR || './images/logo/';
export const getLogoDir = process.env.GET_LOGO_DIR || '/images/logo/';

// OG Image Upload directory
export const ogImageUploadDir =
  process.env.OGIMAGE_UPLOAD_DIR || './images/og-image/';
export const getOgImageUploadDir =
  process.env.GET_OGIMAGE_UPLOAD_DIR || '/images/og-image/';

export const analytics = {
  // https://analytics.google.com/
  google: {
    trackingId: process.env.GOOGLE_MAP_TRACKING_ID,
  },
};

//Google Maps
export const googleMapAPI = process.env.GOOGLE_MAP_API_KEY;
export const googleMapServerAPI = process.env.GOOGLE_MAP_API_KEY;

//Payments
export const payment = {
  paypal: {
    returnURL: `${url}${process.env.PAYPAL_RETURN_URL}`,
    cancelURL: `${url}${process.env.PAYPAL_CANCEL_URL}`,
    redirectURL: {
      success: `${url}${process.env.PAYPAL_SUCCESS_REDIRECT_URL}`,
      cancel: `${url}${process.env.PAYPAL_CANCEL_URL}`,
    },
    versions: {
      versionOne: '/v1',
      versionTwo: '/v2',
    },
    token_url: '/oauth2/token',
    payment_url: '/checkout/orders',
    capture_url: '/capture',
    payout_url: '/payments/payouts',
    refund: '/refund',
    refund_capture: '/payments/captures/',
  },

  stripe: {
    secretKey: process.env.STRIPE_SECRET /* From ENV */,
    publishableKey: process.env.STRIPE_KEY,
  },
};

// site key for google recaptcha
export const googleCaptcha = {
  sitekey: process.env.GOOGLE_CAPTCHA_KEY,
};

export const veriff = {
  apiKey: process.env.VERIFF_API_KEY,
  webhookSecret: process.env.VERIFF_WEBHOOK_SECRET,
};

export const auth = {
  jwt: { secret: process.env.JWT_SECRET },

  redirectURL: {
    login: process.env.LOGIN_URL || '/dashboard',
    verification: process.env.LOGIN_URL || '/user/verification',
    userbanned: process.env.USER_BANNED_URL || '/userbanned',
    returnURLDeletedUser: process.env.DELETE_USER_URL || '/userbanned',
  },

  // https://developers.facebook.com/ IT IS REMOVED ON THE FUNCTIONING CODE.
  facebook: {
    returnURL:
      process.env.FACEBOOK_CLIENT_URL || `${url}/login/facebook/return`,
  },

  // https://cloud.google.com/console/project
  google: {
    returnURL: process.env.GOOGLE_CLIENT_URL || `${url}/login/google/return`,
  },
};

//Socket URL
export const socketUrl = process.env.SOCKET_URL || 'http://localhost:4001';
export const socketPort = process.env.SOCKET_PORT || 4001;

//Coinbase
export const coinbaseURL = process.env.COINBASE_URL;

//CRON
export const cronTimezone = process.env.CRON_TIMEZONE;
