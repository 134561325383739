import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
// Redux
import { connect } from 'react-redux';
// Redux Form
import {
  Field,
  reduxForm,
  formValueSelector,
  change,
  getFormSyncErrors,
  submit as submitForm,
  getFormValues,
} from 'redux-form';
import { Button, FormGroup, FormControl } from 'react-bootstrap';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
// Stripe
import { injectStripe } from 'react-stripe-elements';
// Components
import Loader from '../../Loader/Loader';
import CountryList from '../../CountryList/CountryList';
// Helpers
import validatePayoutForm from './validatePayoutForm';
import submit from './submit';
import generateStripePayoutToken from '../../../helpers/generateStripePayoutToken';
// Redux actions
import {
  startPayoutLoading,
  stopPayoutLoading,
} from '../../../actions/Payout/addPayoutAction';
// Locale
import messages from '../../../locale/messages';
import showToaster from '../../../helpers/toasterMessages/showToaster';
import s from '../Payout.css';
import cs from '../../../components/commonStyle.css';

class PayoutDetailsForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.any.isRequired,
    formatMessage: PropTypes.any,
    previousPage: PropTypes.any.isRequired,
    siteName: PropTypes.string.isRequired,
    stripe: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.handleSubmitAction = this.handleSubmitAction.bind(this);
  }

  static defaultProps = {
    businessType: 'individual',
  };

  renderField = ({
    input,
    label,
    meta: { touched, error },
    placeHolder,
    className,
    maxLength,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <FormGroup className={cx(cs.spaceBottom4, className)}>
        <label>{label}</label>
        <FormControl
          {...input}
          componentClass="input"
          className={cx(cs.formControlInput, 'commonInputPaddingRTL')}
          placeholder={placeHolder}
          maxLength={maxLength}
        />
        {touched && error && (
          <span className={cs.errorMessage}>{formatMessage(error)}</span>
        )}
      </FormGroup>
    );
  };

  renderSelectField = ({
    input,
    label,
    meta: { touched, error },
    children,
    placeHolder,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <FormGroup className={cs.spaceBottom4}>
        <label>{label}</label>
        <FormControl
          {...input}
          componentClass="select"
          className={cs.formControlSelect}
          placeholder={placeHolder}
        >
          {children}
        </FormControl>
        {touched && error && (
          <span className={cs.errorMessage}>{formatMessage(error)}</span>
        )}
      </FormGroup>
    );
  };

  renderCountryList = ({ input, meta: { touched, error } }) => {
    const { formatMessage } = this.props.intl;
    return (
      <FormGroup className={cs.spaceBottom4}>
        <label>
          <FormattedMessage {...messages.country} />
        </label>
        <CountryList input={input} className={cs.formControlSelect} />
        {touched && error && (
          <span className={cs.errorMessage}>{formatMessage(error)}</span>
        )}
      </FormGroup>
    );
  };

  async handleSubmitAction(event) {
    event.preventDefault();
    const { stripe, change, handleSubmit } = this.props;
    const { formValues, errors, startPayoutLoading, stopPayoutLoading } =
      this.props;
    let accountToken, personToken;

    if (errors && JSON.stringify(errors) !== '{}') {
      await handleSubmit();
    }

    if (
      (!errors || (errors && JSON.stringify(errors) === '{}')) &&
      formValues &&
      stripe
    ) {
      await startPayoutLoading();
      const generateTokens = await generateStripePayoutToken(
        stripe,
        formValues
      );
      if (generateTokens && generateTokens.status === 200) {
        accountToken =
          generateTokens.result && generateTokens.result.accountToken;
        personToken =
          (generateTokens.result && generateTokens.result.personToken) || null;
        await change('isTokenGenerated', true);
        await change('accountToken', accountToken);
        await change('personToken', personToken);
        await handleSubmit();
      } else {
        showToaster({
          messageId: 'commonError',
          toasterType: 'error',
          requestMessage: generateTokens.errorMessage,
        });
        await stopPayoutLoading();
      }
      return;
    }
  }

  render() {
    const { handleSubmit, pristine, submitting, error } = this.props;
    const { payoutLoading, businessType } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <div
        className={cx(
          'inputFocusColor',
          cs.commonBorderSection,
          'whiteBgColor'
        )}
      >
        <form onSubmit={handleSubmit}>
          <h3 className={cx(cs.commonTotalText, cs.paddingBottom4)}>
            {formatMessage(messages.addPayout)}
          </h3>
          <Field name="country" component={this.renderCountryList} />
          <Field
            name="address1"
            component={this.renderField}
            label={formatMessage(messages.address1)}
            maxLength={255}
          />
          <Field
            name="address2"
            component={this.renderField}
            label={formatMessage(messages.address2)}
            maxLength={255}
          />
          <div
            className={cx(
              s.payoutTitleFlex,
              s.spaceBetWeen,
              s.flexColumMobile,
              s.alignItemBaseLine
            )}
          >
            <Field
              name="city"
              component={this.renderField}
              label={formatMessage(messages.city)}
              className={s.cityWidth}
              maxLength={255}
            />
            <Field
              name="state"
              component={this.renderField}
              label={formatMessage(messages.state)}
              className={s.cityWidth}
              maxLength={255}
            />
          </div>
          <Field
            name="zipcode"
            component={this.renderField}
            label={formatMessage(messages.zipCode)}
            maxLength={30}
          />
          <Field
            name="businessType"
            component={this.renderSelectField}
            label={formatMessage(messages.payoutType)}
          >
            <option value="individual">
              {formatMessage(messages.payoutIndividual)}
            </option>
            <option value="company">
              {formatMessage(messages.payoutCompany)}
            </option>
          </Field>
          <div
            className={cx(
              s.payoutTitleFlex,
              s.spaceBetWeen,
              s.flexColumMobile,
              s.alignItemBaseLine
            )}
          >
            <Field
              name="firstname"
              component={this.renderField}
              className={cx([
                businessType === 'individual' ? s.cityWidth : s.fullWidth,
              ])}
              label={
                businessType === 'individual'
                  ? formatMessage(messages.payoutFirstName)
                  : formatMessage(messages.payoutCompanyName)
              }
              placeHolder={
                businessType === 'individual'
                  ? formatMessage(messages.payoutFirstName)
                  : formatMessage(messages.payoutCompanyName)
              }
              maxLength={255}
            />
            {businessType && businessType === 'individual' && (
              <Field
                name="lastname"
                className={s.cityWidth}
                component={this.renderField}
                label={formatMessage(messages.payoutLastName)}
                placeHolder={formatMessage(messages.payoutLastName)}
                maxLength={255}
              />
            )}
          </div>
          <p className={cx(cs.commonMediumText, cs.paddingBottom2)}>
            {formatMessage(messages.payoutStripeInfo)}
          </p>
          <p className={cs.commonMediumText}>
            {formatMessage(messages.stripeTokenInfo1)}{' '}
            <a
              href={'https://stripe.com/connect-account/legal'}
              target={'_blank'}
              className={cs.siteLinkColor}
            >
              {formatMessage(messages.stripeTokenInfo2)}
            </a>
            .
          </p>
          <div className={cx(s.btnFlex, cs.spaceTop4)}>
            <Button
              className={cx(cs.btnPrimaryBorder, s.btnRight, 'payoutBackRTL')}
              onClick={() => history.push('/user/payout')}
            >
              <FormattedMessage {...messages.back} />
            </Button>
            <Loader
              type={'button'}
              buttonType={'button'}
              className={cs.btnPrimary}
              disabled={pristine || submitting || error || payoutLoading}
              show={payoutLoading}
              label={formatMessage(messages.finish)}
              handleClick={this.handleSubmitAction}
            />
          </div>
        </form>
      </div>
    );
  }
}

PayoutDetailsForm = reduxForm({
  form: 'PayoutForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validatePayoutForm: validatePayoutForm,
  onSubmit: submit,
})(PayoutDetailsForm);

const selector = formValueSelector('PayoutForm');

const mapState = (state) => ({
  siteName: state.siteSettings.data.siteName,
  availableCurrencies: state.currency.availableCurrencies,
  base: state.currency.base,
  payoutLoading: state.reservation.payoutLoading,
  businessType: selector(state, 'businessType'),
  payoutCountry: selector(state, 'country'),
  formValues: getFormValues('PayoutForm')(state),
  errors: getFormSyncErrors('PayoutForm')(state),
});

const mapDispatch = {
  submitForm,
  change,
  startPayoutLoading,
  stopPayoutLoading,
};

export default injectStripe(
  injectIntl(
    withStyles(s, cs)(connect(mapState, mapDispatch)(PayoutDetailsForm))
  )
);
