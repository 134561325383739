import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import { Button } from 'react-bootstrap';
import { graphql } from 'react-apollo';
import { userDocumentVerification } from '../../../lib/graphql';
import cs from '../../../components/commonStyle.css';

// Locale
import messages from '../../../locale/messages';

class VeriffButton extends React.Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isClient: false,
    };
  }

  componentDidMount() {
    this.setState({ isClient: true });
  }

  updateUserVerifiedInfo = async ({ userId, providerVerificationStatus }) => {
    const { mutate } = this.props;
    return await mutate({
      mutation: userDocumentVerification,
      variables: {
        userId,
        providerVerificationStatus,
      },
    });
  };

  handleVeriffStart = async () => {
    const { firstName, lastName, userId, providerVerificationStatus } =
      this.props;

    this.setState({ isLoading: true });

    try {
      // Call your backend to create a session
      const response = await fetch('/create-veriff-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ firstName, lastName, userId }),
      });
      const sessionData = await response.json();
      if (response.status === 200) {
        const data = await this.updateUserVerifiedInfo({
          userId,
          providerVerificationStatus: 'started',
        });
        if (data.data.userDocumentVerification.status === 'success') {
          window.location.href = sessionData.verification.url;
        } else {
          showToaster({ messageId: 'veriffError', toasterType: 'error' });
        }
      }
    } catch (error) {
      console.error('Error starting Veriff session:', error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { formatMessage } = this.props.intl;
    const { isLoading } = this.state;

    return (
      <div>
        <Button
          onClick={this.handleVeriffStart}
          disabled={isLoading}
          className={cx(cs.btnPrimary)}
        >
          {isLoading
            ? formatMessage(messages.loadingLabel)
            : formatMessage(messages.startVerification)}
        </Button>
      </div>
    );
  }
}

const mapState = (state) => ({
  userId: state.account.data.userId,
  firstName: state.account.data.firstName,
  lastName: state.account.data.lastName,
});

const mapDispatch = {};

export default injectIntl(
  graphql(userDocumentVerification)(
    withStyles(cs)(connect(mapState, mapDispatch)(VeriffButton))
  )
);
